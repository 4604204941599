import React,{useState} from 'react'
import MidnightSparkle1 from '../../IMAGE/Afro Ring Website/MidnightSparkle.png'
import MidnightSparkle2 from '../../IMAGE/Afro Ring Website/MidnightSparkle1.png'
import MidnightSparkle3 from '../../IMAGE/Afro Ring Website/MidnighSparkle2.png'

import { IoDiamondOutline } from "react-icons/io5";
const MidnightSparkle = () => {

    // Defining the array of products with multiple images
  const products = [
    {
       img1: MidnightSparkle1,
      img2: MidnightSparkle2,
      img3: MidnightSparkle3,
      alt: "Midnight Sparkle",
      label: "Midnight Sparkle Ring",
      sku: "EJADF884",
      whatsapp: " ", // Consider adding a valid WhatsApp link if needed
      desc: "This exquisite diamond ring features a delicate flower design, elegantly crafted to capture the beauty of nature. The shimmering diamonds are meticulously set to enhance the floral motif, making it a perfect symbol of love and elegance. Ideal for special occasions or as a timeless addition to any jewelry collection, this ring embodies sophistication and charm.",
    },
  ];

  // State for managing the main image display
  const [mainImage, setMainImage] = useState(products[0].img1);

  // Function to change the main image
  const changeImage = (src) => {
    setMainImage(src);
  };



  return (
    <div className="flex items-center justify-center min-h-screen px-4 md:px-8 lg:px-16 bg-gray-900 text-white">
    <div className="container mx-auto py-8">
      <div className="flex flex-wrap justify-center items-start space-y-8 md:space-y-0 md:space-x-8">
        {/* Main Image Section */}
        <div className="w-full md:w-1/3 lg:w-1/3 px-4 mb-8">
          <img
            src={mainImage}
            alt="Product"
            className="w-full h-auto rounded-lg shadow-md mb-4"
            id="mainImage"
          />
          {/* Thumbnails */}
          <div className="flex gap-4 py-4 justify-center overflow-x-auto">
            {Object.keys(products[0])
              .filter((key) => key.startsWith('img'))
              .map((key, index) => (
                <img
                  key={index}
                  src={products[0][key]}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                  onClick={() => changeImage(products[0][key])}
                />
              ))}
          </div>
        </div>

        {/* Product Details Section */}
        <div className="w-full md:w-2/3 lg:w-2/3 px-4 mb-8">
          {products.map((product, index) => (
            <div key={index} className="w-full mb-8">
              <h2 className="text-3xl font-bold mb-2">{product.label}</h2>
              <p className="text-gray-300 mb-4">SKU: {product.sku}</p>
              <p className="text-gray-300 mb-6">{product.desc}</p>
              <div className="flex space-x-4 mb-6 lg:pt-4">
                <button className="bg-indigo-600 flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <IoDiamondOutline className="text-2xl" />
                  Get Price
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  )
}

export default MidnightSparkle