import React, { useState } from 'react'
import './Contact.css'
import Footer from '../Footer/Footer.tsx'

const Contact = () => {
 


  const hours = [
    { day: "Monday - Friday", time: "10:00 AM - 7:00 PM" },
  ];

  return (
    <div className='location-sec'>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3475.741248891675!2d55.4375!3d25.4036311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5b0ebc503bc1%3A0xe93c3295426cfc9b!2s25%C2%B024%2713.1%22N%2055%C2%B026%2715.0%22E!5e0!3m2!1sen!2sae!4v1697635417872!5m2!1sen!2sae"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
          className="responsive-iframe"
        >
        </iframe>
      </div>

      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-item">
            <i className="fas fa-map-marker-alt icon"></i>
            <div>
              <h3>Location</h3>
              <p>Showroom #02, Abdul Kareem, AL Abdul building, Geepas tower, behind giant supermarket, Rumaila 01, Ajman - U.A.E</p>
            </div>
          </div>

          <div className="contact-item">
            <i className="fas fa-clock icon"></i>
            <div>
              <h3>Open Hours</h3>
              <div className="opening-hours-container">
                {hours.map((entry, index) => (
                  <p key={index} className="time-entry">
                    {entry.day}: <span>{entry.time}</span>
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="contact-item">
            <i className="fas fa-phone-alt icon"></i>
            <div>
              <h3>Call Us</h3>
              <p><a href="tel:+971554604312">+971-554604312</a></p>
            </div>
          </div>

          <div className="contact-item">
            <i className="fas fa-envelope icon"></i>
            <div>
              <h3>Email Us</h3>
              <p><a href="mailto:askafroasiatic@gmail.com">askafroasiatic@gmail.com</a></p>
            </div>
          </div>
        </div>

        <div className="contact-form">
        <form action="https://formsubmit.co/70ef8e5a3f0098994ecb2c5ffc750d19" method="POST">
 
  <div>
    <label for="name">Your Name</label>
    <input 
      type="text" 
      id="name" 
      name="name" 
      placeholder="Your Name" 
      required 
      aria-label="Your Name"
    />
  </div>


  <div>
    <label for="email">Your Email</label>
    <input 
      type="email" 
      id="email" 
      name="email" 
      placeholder="Your Email" 
      required 
      aria-label="Your Email"
    />
  </div>

  
  <div>
    <label for="subject">Subject</label>
    <input 
      type="text" 
      id="subject" 
      name="subject" 
      placeholder="Subject" 
      required 
      aria-label="Subject"
    />
  </div>


  <div>
    <label for="message">Message</label>
    <textarea 
      id="message" 
      name="message" 
      placeholder="Message" 
      rows="5" 
      required 
      aria-label="Your Message"
    ></textarea>
  </div>

  <button type="submit">Send Message</button>
</form>

        </div> 
      </div>

    </div>
  )
}

export default Contact;
