import React   from 'react';
import './WelcomeSection.css'; 
import "./GivaEssentials.css";
import WeddingNecklace from '../IMAGE/Wedding Necklace.png'
import FloralPendent from '../IMAGE/FloralPendentn.png'
import Bangles from '../Jewelleryimg/Bnglesimg.JPG';
import Earring from '../Jewelleryimg/Earing.JPG';
import Necklace from '../Jewelleryimg/Necklaceset.jpg';
import princia from '../IMAGE/princiaphoto.png'
import { Helmet } from 'react-helmet-async';
import BACKIMG from '../IMAGE/BACKGROUNDIMAGES.png';
import { Link } from 'react-router-dom';
import  DiamondDreamRing from '../IMAGE/Afro Ring Website/DiamondDreamRing.png'
const WelcomeSection = () => {
 
  return (
    <div>
      <Helmet>
      <title>Afro Asiatic Fusion Accessories</title>
        <meta name="description" content="Description of the home page" />
        <meta name="keywords" content="jewelry, diamonds, necklaces, earrings, rings" />
      </Helmet>
      
          <section className="welcome-section">
            <img src={BACKIMG}/>
      <div className="overlay">
        <div className="welcome-content">
          <h1 className='font-sans'>Welcome to</h1>
          <p className='font-sans text-amber-500'>Afro Asiatic Fusion Accessories</p>
          <p className="location">D U B A I</p>
        </div>
      </div>
    </section>
    <section className="about-section">
  <div className="about-container">
    {/* <!-- Left Text Section --> */}
    <div className="about-text">
      <h1>Afro Asiatic Fusion Accessories</h1>
      <p>
        Manufacturer, wholesaler, exporter, supplier, stockist of high-quality fashion jewellery. We provide exquisite jewellery from highly talented designers. We bring you a wide range of designer fusion art jewellery, which is a blend of superior quality, beautiful design & excellent craftsmanship. Our collection of fashionable jewellery has been designed by expert designers and handcrafted by the most talented artisans.
      </p>
      <ul>
        <li>Afroasiatic Fusion Art Jewellery offers a wide variety of exclusive rings.</li>
        <li>Breakdown due to undertaking Afro Art Fashion Jewellery Latest Design.</li>
      </ul>
    </div>

    {/* <!-- Right Image Section --> */}
    <div className="about-image">
        <img src={princia} alt='Ms. Princia Rodrigues' />
     {/* <img src="your-image-url" alt="Ms. Princia Rodrigues"> */}
      {/* <h3>Ms. Princia Rodrigues</h3>
      <p>Head of Fashion Jewellery</p> */}
    </div>
  </div>
</section>

<div className="jewellery-section">
      <h3>WHY US</h3>
      <h2>Why Choose Our Jewellery</h2>
      <div className="jewellery-items">
        <div className="jewellery-item">
          {/* Bangles SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="12" cy="12" r="8" stroke="gold" strokeWidth="2" />
            <rect x="8" y="8" width="8" height="8" fill="pink" />
          </svg> */}
          <img src={Bangles} className="jewellery-icon"/>
          <p>BANGLES</p>
        </div>

        <div className="jewellery-item">
      <Link to="/ring" style={{ textDecoration: 'none', color: 'inherit' }}>
        {/* <img 
          src='https://www.giva.co/cdn/shop/files/GDLR0128_5.jpg?v=1689855533&width=713' 
          alt="Ring" 
          className='jewellery-icon' 
        /> */}
        <img src= {DiamondDreamRing} className='jewellery-icon'/>
        <p>RING</p>
      </Link>
    </div>

        <div className="jewellery-item">
          {/* Necklace SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="12" cy="10" r="8" stroke="gold" strokeWidth="2" />
            <rect x="11" y="12" width="2" height="4" fill="red" />
          </svg> */}
           <img src={Necklace} className="jewellery-icon"/>
          <p>Necklace Set</p>
        </div>

        <div className="jewellery-item">
          {/* Earrings SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="10" cy="8" r="2" fill="red" />
            <circle cx="14" cy="8" r="2" fill="red" />
            <polygon points="8,10 12,18 16,10" fill="orange" />
          </svg> */}
            <img src={Earring} className="jewellery-icon"/>
          <p>Earrings</p>
        </div>
      </div>
     
    </div>
    <div className="giva-container">
      <h2>Afro Essentials</h2>
      <div className="giva-grid">
        <div className="giva-item">
          <div className="giva-image-wrapper">
            <img
              src={WeddingNecklace}
              alt="Gold with Lab Diamonds"
              className="giva-image"
            />
            {/* <span className="giva-tag">0% Making Charges</span> */}
          </div>
          <p>Golden Jewellery</p>
        </div>
        <div className="giva-item">
          <img src={FloralPendent}
            alt="Silver Jewellery"
            className="giva-image"
          />
          <p>American Diamond Jewellery</p>
          {/* <span className="giva-tag">0% Making Charges</span> */}
        </div>
      </div>
    </div>

    
    </div>
  
  );
};

export default WelcomeSection;
