import React, { useState } from 'react';
import Image from "../IMAGE/Logos.svg";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Navbar.css';
import { faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';

// import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import AQJ from './AnitiqueJewellery/AQJ.tsx';

const Navbar: React.FC = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const[isMenuOpen,setMenuOpen] = useState(false);
  const toggleDropdown = (dropdown: string) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };
  const toggleMobileMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <>
     <div className="bg-black text-white py-2 flex justify-between items-center text-sm px-1 sm:px-1 md:px-2 lg:px-16">
  <div className="flex items-center space-x-4">
    <div className="flex items-center space-x-2">
      <FontAwesomeIcon icon={faEnvelope} className="text-yellow-500" />
      <a href="mailto:askafro@gmail.com" className="hover:text-yellow-500">
        askafroasiatic@gmail.com
      </a>
    </div>
    <div className="flex items-center space-x-2">
      <FontAwesomeIcon icon={faPhone} className="text-yellow-500" />
      <a href="tel:+971554604312" className="hover:text-yellow-500">
        +971-554604312
      </a>
    </div>
  </div>

  {/* Social Icons - Right-hand side */}
  <div className="flex items-center space-x-4 text-lg">
    <a href="https://www.instagram.com" className="hover:text-yellow-500">
      <FontAwesomeIcon icon={faInstagram} className="text-white-500" />
    </a>
    <a href="https://www.facebook.com" className="hover:text-yellow-500">
      <FontAwesomeIcon icon={faFacebook} className="text-White-500" />
    </a>
  </div>
</div>

      {/* Main navbar */}
      <header className="shadow-md bg-black font-[sans-serif] tracking-wide relative z-50 text-white text-sm px-16 sm:px-0 md:px-0 lg:px-16 py-0">
        <section className="flex items-center justify-between lg:justify-between p-0.5">
          <Link to="/WelcomeSection" className="shrink-0">
            <img src={Image} alt="logo" className="sm:w-32 md:w-32 lg:w-40 xl:w-60 2xl:w-[400px] h-auto" />
          </Link>
          
{/* Hamburger Icon for Mobile */}
          <div className="lg:hidden">
            <button onClick={toggleMobileMenu} className="focus:outline-none">
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
 {/* Mobile Menu */}
 {isMenuOpen && (
            <div className="lg:hidden absolute top-16 left-0 w-full bg-black shadow-lg z-40">
              <ul className="flex flex-col space-y-4 p-4">
                <li><Link to="/" className="hover:text-yellow-500">Home</Link></li>
                <li>
                  <span onClick={() => toggleDropdown('designerJewellery')} className="hover:text-yellow-500 cursor-pointer">American Diamond Jewellery</span>
                  {/* {activeDropdown === 'designerJewellery' && (
                    <ul className="pl-4">
                      <li><a href="#" className="hover:text-yellow-500">Temple Jewellery</a></li>
                      <li><a href="#" className="hover:text-yellow-500">Antique Earrings</a></li>
                    </ul>
                  )} */}
                </li>
                <li><Link to="/about" className="hover:text-yellow-500">Kundan Jewellery</Link></li>
                <li><Link to="/contact" className="hover:text-yellow-500">Oxidized Jewellery</Link></li>
                <li><Link to="/about" className="hover:text-yellow-500">About Us</Link></li>
                <li><Link to="/contact" className="hover:text-yellow-500">Contact</Link></li>
              </ul>
            </div>
          )}
          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:flex-row lg:gap-x-10 lg:items-center lg:ml-auto navbar">
            <ul className="flex space-x-10">
              <li>
                <Link to='/WelcomeSection' className="hover:text-[#bd8c39] font-semibold text-white">Home</Link>
              </li>

              <li className="relative">
                <span
                  onClick={() => toggleDropdown('designerJewellery')}
                  className="hover:text-[#cba33e] font-semibold text-white flex items-center cursor-pointer"
                >
                  Designer Jewellery
                </span>
                {activeDropdown === 'designerJewellery' && (
                  <div className="dropdown-menu grid grid-cols-6 gap-5 p-4 bg-black text-white mx-auto">
                    {/* Dropdown content */}
                    <div>
                      <h3 className="text-gold font-bold"><Link to="/Antiquejewellery">Temple Jewellery</Link></h3>
                      <ul>
                        <li><a href="#">Temple Jewellery Necklace Sets</a></li>
                        <li><a href="#">Temple Jewellery Earings</a></li>
                        <li><a href="#">Temple Necklace Finger Rings</a></li>
                        <li><a href="#">Temple Jewellery Choti</a></li>
                        <li><a href="#">Temple Jewellery Bajuband</a></li>
                        <li><a href="#">Temple Jewellery Dulhan Set</a></li>
                        <li><a href="#">Temple Bangles</a></li>
                        <li><a href="#">Temple Coin Bangles</a></li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-gold font-bold"><a href="">Antique Jewellery</a></h3>
                      <ul>
                        <li><a href="#">Antique Earrings</a></li>
                        <li><a href="#">Antique Pendant Sets</a></li>
                        <li><a href="#">Antique Hath Panjas</a></li>
                        <li><a href="#">Antique Payal</a></li>
                        <li><a href="#">Antique Bangles</a></li>
                        <li><a href="#">Antique Nath</a></li>
                        <li><a href="#">Antique Hair Accessories</a></li>
                        <li><a href="#">Antique Finger Ring</a></li>
                        <li><a href="#">Antique Maang Tikka</a></li>
                        <li><a href="#">Antique Necklace Sets</a></li>
                        <li><a href="#">Antique Kamar Patta</a></li>
                        <li><a href="#">Antique Toe Ring</a></li>
                        <li><a href="#">Antique Dulhan Set</a></li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-gold font-bold"><a href="">Kundan Jewellery</a></h3>
                      <ul>
                        <li><a href="#">Kundan Dulhan Sets</a></li>
                        <li><a href="#">Kundan Rings</a></li>
                        <li><a href="#">Kundan Pendants</a></li>
                        <li><a href="#">Kundan Necklaces Sets</a></li>
                        <li><a href="#">Kundan Bangles</a></li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-gold font-bold"><a href="">Fusion Jewellery</a></h3>
                      <ul>
                        <li><a href="#">Fusion Finger Rings</a></li>
                        <li><a href="#">Fusion Earrings</a></li>
                        <li><a href="#">Fusion Necklace Sets</a></li>
                        <li><a href="#">Fusion Pendant Sets</a></li>
                        <li><a href="#">Fusion Bracelets</a></li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-gold font-bold"><a href="">CZ/American Diamond Jewellery</a></h3>
                      <ul>
                        <li><Link to="/ring">AD Finger Rings</Link></li>
                        <li><a href="#">American Diamond Earrings</a></li>
                        <li><a href="#">AD Necklace Sets</a></li>
                        <li><a href="#">AD Pendant Sets</a></li>
                        <li><a href="#">AD Bracelets</a></li>
                        <li><a href="#">AD Bangles</a></li>
                        <li><a href="#">AD Kada</a></li>
                        <li><a href="#">AD Bajuband</a></li>
                        <li><a href="#">AD Mangalsutra</a></li>
                        <li><a href="#">AD Maang Tikka</a></li>
                        <li><a href="#">AD Payal</a></li>
                        <li><a href="#">AD Hath</a></li>
                        <li><a href="#">AD Kamar Patta</a></li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>

              <li className="relative jewellery-dropdown">
                <span
                  onClick={() => toggleDropdown('jewellery')}
                  className="hover:text-[#cba33e] font-semibold text-white flex items-center cursor-pointer"
                >
                  Jewellery
                </span>
                {activeDropdown === 'jewellery' && (
                  <div className="dropdown-menu grid grid-cols-6 gap-5 p-4 bg-black text-white -ml-40">
                    <div>
                      <ul>
                        <li><a href="#">Earings</a></li>
                        <li><a href="#">Bangles</a></li>
                        <li><a href="#">Nath</a></li>
                        <li><a href="#">Kada</a></li>
                        <li><a href="#">Chain</a></li>
                        <li><a href="#">Bindi</a></li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li><a href="#"> Necklace Sets</a></li>
                        <li><a href="#">Sindoor Box</a></li>
                        <li><a href="#">Juda</a></li>
                        <li><a href="#">Finger Rings</a></li>
                        <li><a href="#"> Bracelets</a></li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li><a href="#">Pendent Sets </a></li>
                        <li><a href="#">Maang Tikka</a></li>
                        <li><a href="#">Payal</a></li>
                        <li><a href="#">Mangalsutra</a></li>
                        <li><a href="#">Bajuband</a></li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li><a href="#">Designer Pendent Sets</a></li>
                        <li><a href="#">Maang Tikka Earrings</a></li>
                        <li><a href="#">Kamar Pata</a></li>
                        <li><a href="#">Hath Paan</a></li>
                        <li><a href="#">Rakhi</a></li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li><a href="#">Dulhan Set</a></li>
                        <li><a href="#">Toe Ring</a></li>
                        <li><a href="#"> Brooch</a></li>
                        <li><a href="#"> Key Chains</a></li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>

              <li>
                <Link to='/About' className="hover:text-[#c5a13d] font-semibold text-white">About Us</Link>
              </li>
              <li>
                <Link to='/Contact' className="hover:text-[#c5a13d] font-semibold text-white">Contact</Link>
              </li>
            </ul>
          </div>
        </section>
      </header>
    </>
  );
};

export default Navbar;
