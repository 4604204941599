import React from 'react'
import Ringcollection from '../IMAGE/Afro Ring Website/Ring collection.png'
import CrystalDiamond from '../IMAGE/Afro Ring Website/CrystalDiamond1.png'
import BowRing from '../IMAGE/Afro Ring Website/BowtifulBling.png'
import RadiantEleganceRing from '../IMAGE/Afro Ring Website/RadiantEleganceRing.png'
import PetalSparkle from '../IMAGE/Afro Ring Website/PetalSparkle.png'
import LuminousPearl from '../IMAGE/Afro Ring Website/LuminousPearl.png'
import BloomingElegance from '../IMAGE/Afro Ring Website/BloomingElegance.png'
import MidnightSparkle from '../IMAGE/Afro Ring Website/MidnightSparkle.png'
import SapphireRadiance from '../IMAGE/Afro Ring Website/SapphireRadiance.png'
import  NoirDiamondTrio from '../IMAGE/Afro Ring Website/NoirDiamondTrio1.png'
import  DiamondDreamRing from '../IMAGE/Afro Ring Website/DiamondDreamRing.png'
import MidnightPetal from '../IMAGE/Afro Ring Website/MidnightPetal1.png'
import LuminousGrace from '../IMAGE/Afro Ring Website/LuminousGrace1.png'
import MidnightRadiance from '../IMAGE/Afro Ring Website/MidnightRadiance1.png'
import TriadEleganceSilverRing from '../IMAGE/Afro Ring Website/Triad EleganceSilverRing.png'
import { Route } from 'react-router-dom'
const Ring = () => {
  const products = [
    { img1: CrystalDiamond, alt: " Radiant Elegance", label: "Radiant Elegance",sku:"EJADFR883",whatsapp:"https://api.whatsapp.com/send?phone=971554604312&text=I%27m%20interested%20in%20knowing%20the%20price%20of%20the%20product-ADRBNK25%0A%0A",route:"/CrystalDiamond"},
    { img1:  BowRing, alt: "BowtifulBling", label: "Bowtiful Bling",sku:"ADSGFR244",whatsapp:"https://wa.me/+971554604312?text=I'm%20interested%20in%20knowing%20the%20price%20of%20the%20product-RBJADNK634",route:"/Leaf"},
    { img1: RadiantEleganceRing, alt: "Radiant Elegance Ring", label: "Radiant Elegance",sku:"EJADFR885",whatsapp:" ",route:"/RadiantEleganceRing"},
    { img1: PetalSparkle, alt: "Pearl Ring", label: "Petal Sparkle Ring",sku:"ADSGFR244",whatsapp:" ",route:"/PetalSparkle"},
    { img1: LuminousPearl, alt: "Sunpearl Ring", label: "Luminous Elegance",sku:"EJADF887",whatsapp:" ",route:"/LuminousPearl"},  
    {img1:BloomingElegance,alt: "Sunflower Ring", label:"Blooming Elegance" , sku:"EJADF886",whatsapp:" ",route:"/BloomingElegance"},
    {img1:MidnightSparkle, alt:"Midnight Sparkle", label:"Midnight Sparkle",sku:"EJADF884",whatsapp:" ", route:"/MidnightSparkle"},
    {img1:SapphireRadiance, alt:"Sapphire Radiance", label:"Sapphire Radiance",sku:"EJADF890",whatsapp:" ", route:"/SapphireRadiance"},
    {img1:NoirDiamondTrio, alt:"NoirDiamondTrio", label:"Noir Diamond Trio",sku:"EJADFR882",whatsapp:" ", route:"/NoirDiamondTrio"},
    {img1:DiamondDreamRing,alt:"DiamondDreamRing",label:"Diamond Dream", sku:"EJADFR878",whatsapp:" ",route:"/DiamondDreamRing"},
    {img1:MidnightPetal,alt:"Midnight Petal Elegance",label:" Diamond Petal Ring", sku:"EJADFR889",route:"/MidnightPetal"},
    {img1:LuminousGrace,alt:"Luminous Grace Band",label:"HeartStone", sku:"SGADFR566",route:"/LuminousGrace"},
    {img1:MidnightRadiance,alt:"Midnight Radiance",label:"Midnight Radiance",sku:"SGADFR565",route:"/MidnightRadiance"},
    {img1:TriadEleganceSilverRing,alt:"Triad Elegance Silver Ring", label:"Trio Gem Ring",sku:"",route:"/TrioGemRing"}
  ]
  return (
    <div>
      <div>
      <img src={Ringcollection} alt="" className='h-3/6 w-full' />
    </div>

    <div className="p-1 flex flex-wrap items-center justify-center min-h-screen">
        {products.map((product, index) => (
          <div key={index} className="flex-shrink-0 m-6 relative overflow-hidden bg-cyan-900 rounded-lg max-w-xs shadow-lg group">
            <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
              viewBox="0 0 375 283" fill="none" style={{ opacity: 0.1 }}>
              <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
              <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
            </svg>
            <div className="relative pt-0 px-0 flex items-center justify-center group-hover:scale-110 transition-transform">
              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}>
              </div>
              <a href={product.route}><img className="relative w-80 h-auto" src={product.img1} alt={product.alt} /></a>
            </div>
            <div className="relative text-white px-6 pb-6 mt-6">
              <div className="flex justify-between">
              <a href={product.route}> <span className="block font-semibold text-xl">{product.label}</span> </a>
                <a href={product.whatsapp}>
                  <span className="bg-black rounded-full text-white text-sm font-medium px-3 py-2 leading-none flex items-center">
                    Get Price
                  </span>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
  )
}

export default Ring