import React from 'react';
import { Link } from 'react-router-dom';
import Regal1 from '../Jewelleryimg/Regal1.png';
import Golden1 from '../Jewelleryimg/Golden Aura1.png';
import Shine1 from '../Jewelleryimg/Luxe Shine1.png';

import Amethyst1 from '../Jewelleryimg/Amethyst Luxe1.png';


import Elegance1  from '../Jewelleryimg/Elegance Set1.png';
import Luxe1  from '../Jewelleryimg/Luxe Gold Duo1.png';    
import cover from '../IMAGE/afrocoverpage.png'             

const ADjewellery = () => {
  const products = [
    { img1: Regal1, alt: "Regal Elegance", label: "Regal Elegance",sku:"HEGFNK674",whatsapp:" ",route:"/Regal"},
    { img1: Golden1, alt: "Golden Aura", label: "Golden Aura",sku:"HEGFNK282",whatsapp:" ",route:"/Golden"}, 
    { img1: Shine1, alt: "Luxe Shine Gold Set", label: "Luxe Shine Gold Set",sku:"HEGFNK61",whatsapp:" ",route:"/Shine"},

    { img1: Amethyst1, alt: "Amethyst Luxe", label: "Amethyst Luxe",sku:"HEGFNK283",whatsapp:" ",route:"/Amethyst"}, 


    { img1: Elegance1, alt: "Elegance Set", label: "Elegance Set",sku:"NFADNK127",whatsapp:" ",route:"/Elegance"},
    { img1: Luxe1, alt: "Luxe Gold Duo", label: " Luxe Gold Duo ",sku:"ANSCNK11",whatsapp:" ",route:"/Luxe"},
  ];

  return (
    <div>
      <div className='home-page'>
        <img 
          src={cover} 
          className="w-full mt-10 object-cover" 
          alt="Jewellery" 
        />
      </div>
      
      <div className="p-1 flex flex-wrap items-center justify-center min-h-screen">
        {products.map((product, index) => (
          <div key={index} className="flex-shrink-0 m-6 relative overflow-hidden bg-indigo-800 rounded-lg max-w-xs shadow-lg group">
            <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
              viewBox="0 0 375 283" fill="none" style={{ opacity: 0.1 }}>
              <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
              <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
            </svg>
            <div className="relative pt-0 px-0 flex items-center justify-center group-hover:scale-110 transition-transform">
              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}>
              </div>
              <Link to={product.route}><img className="relative w-80 h-auto" src={product.img1} alt={product.alt} /></Link>
            </div>
            <div className="relative text-white px-6 pb-6 mt-6">
              <div className="flex justify-between">
              <Link to={product.route}> <span className="block font-semibold text-xl">{product.label}</span> </Link>
                <a href={product.whatsapp}>
                  <span className="bg-black rounded-full text-white text-sm font-medium px-3 py-2 leading-none flex items-center">
                    Get Price
                  </span>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default ADjewellery;
