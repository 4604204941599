import React,{useState} from 'react'

import MidnightRadiance1 from '../../IMAGE/Afro Ring Website/MidnightRadiance1.png'
import MidnightRadiance2 from '../../IMAGE/Afro Ring Website/MidnightRadiance2.png'
// import MidnightRadiance3 from '../../IMAGE/Afro Ring Website/MidnightRadiance3.png'


import { IoDiamondOutline } from "react-icons/io5";
const MidnightRadiance = () => {

    
       // Defining the array of products with multiple images
  const products = [
    {
       img1: MidnightRadiance1,
      img2: MidnightRadiance2,
    //    img3: MidnightRadiance3,
      alt: "Midnight Radiance",
      label: "Midnight Radiance",
      sku: "SGADFR565",
      whatsapp: " ", // Consider adding a valid WhatsApp link if needed
      desc: "This exquisite diamond ring, elegantly positioned on a sleek black surface, showcases its brilliance and craftsmanship. The contrast between the sparkling diamonds and the deep black backdrop enhances the ring's allure, making it a perfect symbol of luxury and sophistication. Ideal for special occasions or as a timeless addition to any jewelry collection, this piece embodies both elegance and style.",
    },
  ];

  // State for managing the main image display
  const [mainImage, setMainImage] = useState(products[0].img1);

  // Function to change the main image
  const changeImage = (src) => {
    setMainImage(src);
  };
  return (
    <div>MidnightRadiance</div>
  )
}

export default MidnightRadiance