import React from 'react';
import IMG from '../IMAGE/pendentset.JPG';
import Bangles from '../IMAGE/kada.PNG';
import Necklaceset from '../IMAGE/Necklaceset.PNG';
import Earing from '../Jewelleryimg/Earing.JPG';


const Gallery = () => {
  return (
    <div className='Gallery-sec'>
      <img src={IMG} className="w-full h-[550px] object-cover" alt="Main Gallery" />

      <div className="flex flex-col md:flex-row justify-center items-center space-x-4 p-4">
        {/* Left Image Section */}
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <img src={Earing} alt="Solitaire Earrings" className="w-full h-auto" />
          <div className="absolute top-20 inset-0 flex justify-center">
            <p className="text-center text-xl text-white font-bold uppercase">Shop Solitaire Earrings</p>
          </div>
        </div>

        {/* Center Promo Section */}
        <div className="flex flex-col items-center justify-center w-full md:w-1/3 bg-beige text-center p-4 mb-4 md:mb-0">
          <p className="text-xl font-bold">Save up to <span className="text-4xl text-white">40%</span></p>
          <p className="text-md mt-2">When you buy solitaire from ZaAmor</p>
        </div>

        {/* Right Image Section */}
        <div className="relative w-full md:w-1/3">
          <img src={Necklaceset} alt="Solitaire Necklace" className="w-full h-auto" />
          <div className="absolute top-20 inset-0 flex justify-center">
            <p className="text-xl text-white font-bold uppercase">Shop Solitaire Necklace</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center py-8 bg-black">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 max-w-6xl text-center text-white">
          
          {/* First Column */}
          <div>
            <div className="flex justify-center mb-2">
              <span className="text-4xl">👍</span> {/* Replace with your actual icon */}
            </div>
            <h3 className="font-bold text-lg">100% GENUINE</h3>
            <p className="text-sm">
              All our jewelry products are accompanied by a valid certificate from an independent international lab attesting the quality of the stones.
            </p>
          </div>
          
          {/* Second Column */}
          <div>
            <div className="flex justify-center mb-2">
              <span className="text-4xl">30 DAYS</span> {/* Replace with your actual icon */}
            </div>
            <h3 className="font-bold text-lg">30 DAYS FULL REFUND</h3>
            <p className="text-sm">
              We value the trust you have placed on us by buying your favorite jewelry from our site.
            </p>
          </div>
          
          {/* Third Column */}
          <div>
            <div className="flex justify-center mb-2">
              <span className="text-4xl">📦</span> {/* Replace with your actual icon */}
            </div>
            <h3 className="font-bold text-lg">FREE SHIPPING</h3>
            <p className="text-sm">
              We take extreme care to ensure that it is delivered to you at your doorstep, safely packed and fully insured.
            </p>
          </div>
          
          {/* Fourth Column */}
          <div>
            <div className="flex justify-center mb-2">
              <span className="text-4xl">🏛️</span> {/* Replace with your actual icon */}
            </div>
            <h3 className="font-bold text-lg">VBJ HERITAGE</h3>
            <p className="text-sm">
              We draw our core values and principles from VBJ – a cherished brand with a 112-year heritage in the jewelry industry.
            </p>
          </div>
          
          {/* Fifth Column */}
          <div>
            <div className="flex justify-center mb-2">
              <span className="text-4xl">💬</span> {/* Replace with your actual icon */}
            </div>
            <h3 className="font-bold text-lg">FAQ & HELP</h3>
            <p className="text-sm">
              A doubt, a question? Reach us at 1-800-102-2667
            </p>
          </div>
          
        </div>
      </div>
      
    </div>
  );
}

export default Gallery;
